import * as common from './common'
import Config from './config'
import { MicSensorBase, VoiceInPayload } from './micSensorBase'

export default class ArraymicSensor extends MicSensorBase<ArraymicVoice> {
  private _arraymicId: string | null = null

  constructor (config: Config) {
    super(config)
  }

  /**
   * センサを設定します。
   *
   * @param spaceId スペース ID
   * @param token NAONA アクセストークン
   */
  configure (spaceId: string, token: string): void {
    super.spaceId = spaceId
    super.token = token
  }

  /**
   * センシングを実行可能かを確認します。
   *
   * 必要に応じて、オーバーライドして確認項目を追加してください。
   */
  async ensureRunnable (): Promise<{ error?: string }> {
    const error = await super.ensureRunnable()
    if (error.error) {
      return error
    }
    const errorOrArraymicId = await this.findArraymic()
    if (errorOrArraymicId.error) {
      return { error: errorOrArraymicId.error }
    } else if (errorOrArraymicId.arraymicId) {
      console.debug('naona arraymic founds: ' + errorOrArraymicId.arraymicId)
      this._arraymicId = errorOrArraymicId.arraymicId
    } else {
      // エラーかアレイマイク ID かなのでここには到達しない
      return { error: 'unexpected error' }
    }
    return {}
  }

  get sampleRate (): number {
    // アレイマイクの元のサンプリングレートは 16000。
    // MediaStreamConstraints などで、8000 など別の値を指定すると
    // リサンプリングされた値が渡されるため、メタデータ（定期的に 2 ch に入力される
    // 音圧、角度）もリサンプリングされまともな値を取れなくなる
    return 16000
  }

  get numChannels (): number {
    return 2
  }

  async findArraymic (): Promise<{ error?: string, arraymicId?: string }> {
    await this.checkMicPermission()

    const devices = await navigator.mediaDevices.enumerateDevices()
    // find naona array mic from all devices.
    const candidates = devices.filter(d => d.label.includes('NAONA Array MIC'))
    if (candidates.length <= 0) {
      return { error: 'naona microphone not founds in ' + JSON.stringify(devices) }
    }
    return { arraymicId: candidates[0].deviceId }
  }

  /**
   * マイクの `MediaStream` を取得する際の `MediaStreamConstraints` を取得します。
   */
  get deviceConstraints (): MediaStreamConstraints {
    // `MediaTrackConstraints`: https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
    // Media Stream API では、デフォルトで音源に対して様々な加工を行ってしまう。
    // アレイマイクにおいては、2 ch に入っている音圧や角度に対して加工がなされると
    // 正しい値が取れないため、これらの加工をすべて無効にすることに注意する。
    if (!this._arraymicId) {
      console.warn('cannot get deviceConstraints. naona arraymic id is not set')
      return {}
    }
    return {
      audio: {
        sampleSize: 16,
        sampleRate: this.sampleRate,
        channelCount: 2,
        noiseSuppression: false,
        autoGainControl: false,
        echoCancellation: false,
        deviceId: this._arraymicId
      },
      video: false
    }
  }

  /**
   * マイクに応じた VAD を行うプロセッサ名を指定します。
   */
  get vadProcessorName (): string {
    return 'arraymic-vad-processor'
  }

  /**
   * VAD で検出された発言を `VoiceInPayload` の形式に整形します。
   * @param voice VAD で検出された発言
   */
  formatVoice (voice: ArraymicVoice): VoiceInPayload {
    return {
      timestamp: voice.timestamp,
      samples: common.arraybufferToString(voice.samples.buffer),
      angle: voice.degree,
      energy: voice.energy
    }
  }
}
