import * as common from './common'
import Config from './config'
import { MicSensorBase, VoiceInPayload } from './micSensorBase'

interface Mic {
  deviceId: string
  label: string
}

export default class MicSensor extends MicSensorBase<Voice> {

  private _deviceId: string | null
  private _speaker: string | null

  private _enableBrowserOptimization: boolean

  constructor (config: Config) {
    super(config)
    this._deviceId = null
    this._speaker = null
    this._enableBrowserOptimization = true
  }

  /**
   * センサを設定します。
   *
   * @param deviceId デバイス ID
   * @param spaceId スペース ID
   * @param speaker 話者名
   * @param token NAONA アクセストークン
   */
  configure (deviceId: string, spaceId: string, speaker: string, token: string): void {
    super.token = token
    super.spaceId = spaceId
    this.deviceId = deviceId
    this.speaker = speaker
  }

  /**
   * センシングを実行可能かを確認します。
   *
   * 必要に応じて、オーバーライドして確認項目を追加してください。
   */
  async ensureRunnable (): Promise<{ error?: string }> {
    const error = await super.ensureRunnable()
    if (error.error) {
      return error
    }
    if (!this.deviceId) {
      return { error: 'device id must be set' }
    }
    if (!this.speaker) {
      return { error: 'speaker is not configured' }
    }
    return {}
  }

  /**
   * マイクの `MediaStream` を取得する際の `MediaStreamConstraints` を取得します。
   */
  get deviceConstraints (): MediaStreamConstraints {
    if (!this.deviceId) {
      return {}
    }
    return {
      audio: {
        deviceId: this.deviceId,
        channelCount: 1,
        sampleSize: 16,
        sampleRate: this.sampleRate,
        // 場合によっては chrome 独自のオプションが必要かもしれない
        // https://source.chromium.org/chromium/chromium/src/+/master:out/android-Debug/gen/third_party/blink/renderer/modules/mediastream/?originalUrl=https:%2F%2Fcs.chromium.org%2F
        // googAudioMirroring: false,
        noiseSuppression: this.enableBrowserOptimization,
        autoGainControl: this.enableBrowserOptimization,
        echoCancellation: this.enableBrowserOptimization
      },
      video: false
    }
  }

  /**
   * マイクに応じた VAD を行うプロセッサ名を指定します。
   */
  get vadProcessorName (): string {
    return 'vad-processor'
  }

  /**
   * VAD で検出された発言を `VoiceInPayload` の形式に整形します。
   * @param {Voice} voice - VAD で検出された発言
   */
  formatVoice (voice: Voice): VoiceInPayload {
    if (!this.speaker) {
      throw new Error('speaker is not set')
    }
    return {
      timestamp: voice.timestamp,
      speaker: this.speaker,
      samples: common.arraybufferToString(voice.samples.buffer)
    }
  }

  /**
   * マイクデバイスのデバイス ID を設定します。
   */
  set deviceId (deviceId: string | null) {
    this._deviceId = deviceId
  }

  /**
   * 現在設定されているデバイス ID を取得します。
   */
  get deviceId (): string | null {
    return this._deviceId
  }

  /**
   * 話者名を設定します。
   */
  set speaker (speaker: string | null) {
    this._speaker = speaker
  }

  /**
   * 話者名を取得します。
   */
  get speaker (): string | null {
    return this._speaker
  }

  /**
   * ブラウザによる最適化を有効にするかどうかを設定します。
   */
  set enableBrowserOptimization (enableBrowserOptimization: boolean) {
    this._enableBrowserOptimization = enableBrowserOptimization
  }

  /**
   * ブラウザによる最適化を有効にするかどうかを取得します。
   */
  get enableBrowserOptimization (): boolean {
    return this._enableBrowserOptimization
  }

  /**
   * 利用可能なマイクの一覧を取得します。
   *
   * オーディオへのアクセスが許可されてない場合、許可を求めるプロンプトが表示されることがあります。
   *
   * @return {Promise<Mic[]>} マイクの一覧
   */
  async list (): Promise<Mic[]> {
    await this.checkMicPermission()

    const devices = await navigator.mediaDevices.enumerateDevices()
    const sensors: Mic[] = devices
      .filter(_ => _.kind === 'audioinput')
      .map(_ => {
        return { deviceId: _.deviceId, label: _.label }
      })

    return sensors
  }
}
