import Config from './config'
import MicSensor from './micSensor'
import ArraymicSensor from './arraymicSensor'

declare const PROVIDER_API_BASE_URL: string
declare const VAD_JS_URL: string

export const config = new Config(PROVIDER_API_BASE_URL, VAD_JS_URL)
export const mic = new MicSensor(config)
export const arraymic = new ArraymicSensor(config)
