export default class Config {
  constructor (private _providerApiBaseUrl: string, private _vadJsUrl: string) {}

  get providerApiBaseUrl (): string {
    return this._providerApiBaseUrl
  }

  set providerApiBaseUrl (value: string) {
    this._providerApiBaseUrl = value
  }

  get vadJsUrl (): string {
    return this._vadJsUrl
  }

  set vadJsUrl (value: string) {
    this._vadJsUrl = value
  }
}
