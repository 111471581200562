import Config from './config'

type VoiceResult = {
  timestamp: number
  recordingtime: number
  speaker: string
}

/* eslint-disable */
type EmotionResult = VoiceResult & {
  em_calm: number
  em_sorrow: number
  em_anger: number
  em_joy: number
  em_energy: number
}
/* eslint-enable */

type NullableNumber = number | null

/* eslint-disable */
type VqResult = VoiceResult & {
  quantity_times: NullableNumber
  clarity_loudness: NullableNumber
  clarity_intonation: NullableNumber
  clarity_opening_mouth: NullableNumber
  clarity_fadeout: NullableNumber
  impression_pitch: NullableNumber
  impression_speed: NullableNumber
  impression_laziness: NullableNumber
  stability_variation_loudness: NullableNumber
  stability_variation_speed: NullableNumber
  stability_variation_pitch: NullableNumber
  balance_countinuous: NullableNumber
  balance_ratio: NullableNumber
  pause_silence_rate: NullableNumber
  pause_silence_length: NullableNumber
  pause_overlap_rate: NullableNumber
  pause_overlap_length_average: NullableNumber
  pause_overlap_length_maximum: NullableNumber
  gender: NullableNumber
  age: NullableNumber
  height: NullableNumber
  weight: NullableNumber
}
/* eslint-enable */

type AnalyzeResult = {
  voice?: VoiceResult[]
  emotion?: EmotionResult[]
  vq?:VqResult[]
}

type SensingError = {
  code: number,
  message: string
}

interface ExtMediaStream extends MediaStream {
  oninactive: (() => void) | null
}

export interface VoiceInPayload {
  timestamp: number
  samples: string
  speaker?: string
  angle?: number
  energy?: number
}

/**
 * `Float32Array` [-1.0, 1.0] 表現のPCM サンプルを `Int16Array` 表現に変換します。
 * @param src `Float32Array` 表現の PCM サンプル
 * @return `Int16Array` 表現の PCM サンプル
 */
const float32ToInt16PCM = (src: Float32Array): Int16Array => {
  const dst = new Int16Array(src.length)
  src.forEach((v, i) => {
    dst[i] = Math.max(Math.min(Math.floor(32767 * v), 32767), -32768)
  })
  return dst
}

/**
 * `Int16Array` 表現の PCM サンプルを `Float32Array` [-1.0, 1.0] 表現に変換します。
 * @param src `Int16Array` 表現の PCM サンプル
 * @return `Float32Array` 表現の PCM サンプル
 */
const int16ToFloat32PCM = (src: Int16Array): Float32Array => {
  const dst = new Float32Array(src.length)
  src.forEach((v, i) => {
    dst[i] = Math.max(Math.min(v / 32767, 1.0), -1.0)
  })
  return dst
}

const resample = (samples: Int16Array, from: number, to: number, onresampled: (resampled: Int16Array) => void) => {
  const offctx = new OfflineAudioContext(1, samples.length * (to / from), to)
  const srcbuf = offctx.createBuffer(1, samples.length, from)
  // TODO サンプルは VAD 中には Float 32 から Int 16 に変換しないほうが良さそう
  srcbuf.copyToChannel(int16ToFloat32PCM(samples), 0)
  const source = offctx.createBufferSource()
  source.buffer = srcbuf
  source.connect(offctx.destination)
  offctx.oncomplete = e => {
    const dstbuf = e.renderedBuffer
    onresampled(float32ToInt16PCM(dstbuf.getChannelData(0)))
  }
  offctx.startRendering()
    .catch((err: Error) => {
      console.trace(`failed to resampe: ${err.message}`)
    })
  source.start(0)
}

const ONLINE_PROVIDER_SAMPLE_RATE = 8000

export abstract class MicSensorBase<T extends Voice> {

  private _config: Config

  private _spaceId: string | null
  private _token: string | null

  private _stream: ExtMediaStream | null
  private _audioCtx: AudioContext | null

  private _ondetected: (voice: T) => void
  private _onanalyzed: (response: AnalyzeResult) => void
  private _onerror: (error: SensingError) => void

  // TODO remove
  private _cotohaClientId: string | null
  private _cotohaClientSecret: string | null

  // TODO remove
  private _onrecorded: null | ((data: Blob) => void)
  private _recordedChunks: null | Float32Array[][]

  constructor (config: Config) {
    this._config = config

    this._spaceId = null
    this._token = null

    this._stream = null
    this._audioCtx = null

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this._ondetected = voice => null
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this._onanalyzed = response => null
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this._onerror = msg => null

    // TODO remove
    this._cotohaClientId = null
    this._cotohaClientSecret = null

    // TODO remove
    this._onrecorded = null
    this._recordedChunks = null
  }

  async checkMicPermission (): Promise<void> {
    // マイクアクセスの権限を取得するために、一度 `getUserMedia` を実行。
    // 初回のみここでプロンプトが表示され、マイクにアクセスしてよいかの確認が
    // なされる。
    // デバイスの一覧をする上でもこの権限が必要となるため、最初に実行する
    // 必要がある。
    const mediaForPermission = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    // 許可を取るためだけなのですぐに無効化する
    // こうしないとデバイスを選択してセンシングを開始するときの getUserMedia で、
    // デバイス ID などによるクエリが聞かず、ここで取得したデフォルトのメディアが取得されてしまう
    mediaForPermission.getAudioTracks().forEach(_ => _.stop())
  }

  /**
   * センシングを実行可能かを確認します。
   *
   * 必要に応じて、オーバーライドして確認項目を追加してください。
   */
  // eslint-disable-next-line @typescript-eslint/require-await
  async ensureRunnable (): Promise<{ error?: string }> {
    if (!this.spaceId) {
      return { error: 'space id must be set' }
    }
    if (!this.token) {
      return { error: 'token is not configured' }
    }
    return {}
  }

  get sampleRate (): number {
    return ONLINE_PROVIDER_SAMPLE_RATE
  }

  get numChannels (): number {
    return 1
  }

  /**
   * マイクの `MediaStream` を取得する際の `MediaStreamConstraints` を取得します。
   */
  abstract get deviceConstraints (): MediaStreamConstraints

  /**
   * マイクに応じた VAD を行うプロセッサ名を取得します。
   */
  abstract get vadProcessorName (): string

  /**
   * VAD で検出された発言を `VoiceInPayload` の形式に整形します。
   * @param voice - VAD で検出された発言
   */
  abstract formatVoice(voice: T): VoiceInPayload

  get config (): Config {
    return this._config
  }

  /**
   * スペース ID を設定します。
   */
  set spaceId (spaceId: string | null) {
    this._spaceId = spaceId
  }

  /**
   * スペース ID を取得します。
   */
  get spaceId (): string | null {
    return this._spaceId
  }

  /**
   * アクセストークンを設定します。
   */
  set token (token: string | null) {
    this._token = token
  }

  /**
   * アクセストークンを取得します。
   */
  get token (): string | null {
    return this._token
  }

  set ondetected (cb: (voice: T) => void) {
    this._ondetected = cb
  }

  set onanalyzed (cb: (result: AnalyzeResult) => void) {
    this._onanalyzed = cb
  }

  set onerror (cb: (error: SensingError) => void) {
    this._onerror = cb
  }

  set onrecorded (cb: (data: Blob) => void) {
    this._onrecorded = cb
  }

  // TODO remove
  get cotohaClientId_ (): string | null {
    return this._cotohaClientId
  }

  // TODO remove
  set cotohaClientId_ (cotohaClientId: string | null) {
    this._cotohaClientId = cotohaClientId
  }

  // TODO remove
  get cotohaClientSecret_ (): string | null {
    return this._cotohaClientSecret
  }

  // TODO remove
  set cotohaClientSecret_ (cotohaClientSecret: string | null) {
    this._cotohaClientSecret = cotohaClientSecret
  }

  get isRunning (): boolean {
    return this._audioCtx !== null && this._stream !== null && this._stream.active
  }

  _resampleVoice (voice: T, onresampled: (resampledVoice: T) => void): void {
    if (this.sampleRate === ONLINE_PROVIDER_SAMPLE_RATE) {
      // サンプリングレートが同じであればそのままでよい
      onresampled(voice)
    } else {
      resample(voice.samples, this.sampleRate, ONLINE_PROVIDER_SAMPLE_RATE, (resampled) => {
        voice.samples = resampled
        onresampled(voice)
      })
    }
  }

  async _publishVoice (voice: T): Promise<AnalyzeResult | null> {
    if (!this.spaceId || !this.token) {
      // ありえない
      throw new Error('no sapce id')
    }
    const url = `${this.config.providerApiBaseUrl}/v1/execute/${this.spaceId || ''}`
    const headers: { [key: string]: string } = {
      'Authorization': `Bearer ${this.token || ''}`
    }
    if (this.cotohaClientId_ && this.cotohaClientSecret_) {
      headers['X-Cotoha-Client-Id'] = this.cotohaClientId_
      headers['X-Cotoha-Client-Secret'] = this.cotohaClientSecret_
    }
    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: headers,
        body: JSON.stringify([
          this.formatVoice(voice)
        ])
      })
      if (!response.ok) {
        console.error(`invalid response: status ${response.status}`)
        return null
      }
      const text = await response.text()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(text.replace(/\bNaN\b/g, "null"))
    } catch (error) {
      console.trace(error)
      return null
    }
  }

  /**
   * センシングを開始します。
   */
  async start (): Promise<void> {
    const error = await this.ensureRunnable()
    if (error.error) {
      this._onerror({ 'code': 200, 'message': error.error })
      return
    }

    const stream = await navigator.mediaDevices.getUserMedia(this.deviceConstraints) as ExtMediaStream
    const audioCtx = new AudioContext({ sampleRate: this.sampleRate })
    this._stream = stream, this._audioCtx = audioCtx

    this._stream.oninactive = () => {
      // mic device is unplugged
      this._onerror({ 'code': 100, 'message': 'inactive mic device' })
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.stop()
    }
    try {
      await audioCtx.audioWorklet.addModule(this._config.vadJsUrl)
    } catch (error) {
      console.trace(error)
      this._onerror({ 'code': 101, 'message': 'network error (failed to register vad worklet)' })
      return
    }

    const inputSource = audioCtx.createMediaStreamSource(stream)
    const vadNode = new AudioWorkletNode(audioCtx, this.vadProcessorName)
    vadNode.port.start()
    vadNode.port.onmessage = (e) => {
      // voice is passed from message port of worklet node when the voice is detected
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const voice: T = e.data
      this._resampleVoice(voice, voice => {
        this._ondetected(voice)
        this._publishVoice(voice)
          .then(result => {
            if (result) {
              this._onanalyzed(result)
            } else {
              this._onerror({ 'code': 101, 'message': 'network error' })
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              this.stop()
            }
          })
          .catch(error => {
            this._onerror({ 'code': 500, 'message': 'unknown error' })
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.stop()
            console.trace(error)
          })
      })
    }

    if (this._onrecorded) {
      // TODO レコーディングまわり適当すぎるのでちゃんと実装する
      const bfSize = 2048, recordedChunks: Float32Array[][] = []
      this._recordedChunks = recordedChunks
      const recorderNode = audioCtx.createScriptProcessor(bfSize, this.numChannels, this.numChannels)
      // TODO should use audio worklet
      recorderNode.onaudioprocess = e => {
        const chunks = []
        for (let ch = 0; ch < e.outputBuffer.numberOfChannels; ch++) {
          const input = e.inputBuffer.getChannelData(ch)
          const buffer = new Float32Array(bfSize)
          input.forEach((d, i) => buffer[i] = d)
          chunks.push(buffer)
          // copy all inputs to outputs for next node
          const output = e.outputBuffer.getChannelData(ch)
          input.forEach((d, i) => output[i] = d)
        }
        recordedChunks.push(chunks)
      }
      inputSource.connect(recorderNode).connect(vadNode).connect(audioCtx.destination)
    } else {
      inputSource.connect(vadNode).connect(audioCtx.destination)
    }
  }

  // export WAV from audio float data
  _exportWAV (recordedChunks: Float32Array[][]): Blob {
    const mergeBuffers = (chunks: Float32Array[][], channel = 1) => {
      let sampleLength = 0
      chunks.forEach(chunk => sampleLength += chunk[channel - 1].length)
      const samples = new Float32Array(sampleLength)
      let sampleIdx = 0
      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i][channel - 1]
        for (let j = 0; j < chunk.length; j++) {
          samples[sampleIdx] = chunk[j]
          sampleIdx++
        }
      }
      return samples
    }

    // https://gist.github.com/meziantou/edb7217fddfbb70e899e
    const interleave = (leftChannel: Float32Array, rightChannel: Float32Array) => {
      const length = leftChannel.length + rightChannel.length
      const result = new Float32Array(length)
      let inputIndex = 0
      for (let index = 0; index < length;) {
        result[index++] = leftChannel[inputIndex]
        result[index++] = rightChannel[inputIndex]
        inputIndex++
      }
      return result
    }

    const floatTo16BitPCM = (output: DataView, offset: number, input: Float32Array) => {
      for (let i = 0; i < input.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, input[i]))
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true)
      }
    }

    const encodeWAV = (samples: Float32Array, sampleRate = ONLINE_PROVIDER_SAMPLE_RATE, rightSamples: null | Float32Array = null) => {
      const writeString = function (view: DataView, offset: number, str: string) {
        for (let i = 0; i < str.length; i++) {
          view.setUint8(offset + i, str.charCodeAt(i))
        }
      }

      const isStereo = rightSamples !== null
      if (rightSamples !== null) {
        samples = interleave(samples, rightSamples)
      }

      const buffer = new ArrayBuffer(44 + samples.length * 2)
      const view = new DataView(buffer)

      writeString(view, 0, 'RIFF')  // RIFFヘッダ
      view.setUint32(4, 32 + samples.length * 2, true) // これ以降のファイルサイズ
      // view.setUint32(4, 44 + samples.length * 2, true) // これ以降のファイルサイズ
      writeString(view, 8, 'WAVE') // WAVEヘッダ
      writeString(view, 12, 'fmt ') // fmtチャンク
      view.setUint32(16, 16, true) // fmtチャンクのバイト数
      view.setUint16(20, 1, true) // フォーマットID
      view.setUint16(22, isStereo ? 2 : 1, true) // チャンネル数
      view.setUint32(24, sampleRate, true) // サンプリングレート
      view.setUint32(28, sampleRate * (isStereo ? 4 : 2), true) // データ速度
      view.setUint16(32, isStereo ? 4 : 2, true) // ブロックサイズ
      view.setUint16(34, 16, true) // サンプルあたりのビット数
      writeString(view, 36, 'data') // dataチャンク
      view.setUint32(40, samples.length * 2, true) // 波形データのバイト数
      floatTo16BitPCM(view, 44, samples) // 波形データ

      return view
    }
    const samples = mergeBuffers(recordedChunks)
    const wavView = this.numChannels === 1 ? encodeWAV(samples, this.sampleRate) : encodeWAV(samples, this.sampleRate, mergeBuffers(recordedChunks, 2))
    return new Blob([wavView], { type: 'audio/wav' })
  }

  async stop (): Promise<void> {
    if (this._onrecorded && this._recordedChunks) {
      this._onrecorded(this._exportWAV(this._recordedChunks))
      this._recordedChunks = null
    }
    if (this._stream) {
      this._stream.oninactive = null
      this._stream.getAudioTracks().forEach(_ => _.stop())
      this._stream = null
    }
    if (this._audioCtx) {
      await this._audioCtx.close()
      this._audioCtx = null
    }
  }
}
